import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const getinvolved = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <Seo location={location} title="Get Involved" />
      <div class="page-charter-wrap">
        <h1>Get Involved</h1>
          <div class="news-wrap">
          <p>Are you sick of the same old divisive & sectarian politics here in Northern Ireland? Well so are we!</p>

          <p>We want to see a politics here not that seeks to stoke the tensions of the past but concentrated on human rights, equality, equity & social justice. More detail can be seen in our <a href="https://progressivepoliticsni.com/charter/">Charter here</a>.</p>

          <p>If you would like to join our team then get in touch at <a href="mailto://info@progressivepoliticsni.com">info@progressivepoliticsni.com</a>.</p>
          </div>
      </div>
    </Layout>
  )
}

export default getinvolved

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
